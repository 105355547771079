var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mockInterview"},[_vm._m(0),_c('div',{staticClass:"containers"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"priceBanner"},[_c('div',{staticClass:"priceBannerArea"},[_c('div',{staticClass:"priceBannerContent"},[_c('img',{attrs:{"src":require("@/assets/imgs/priceBanner.png"),"alt":""}}),_c('div',{staticClass:"priceBannerInner"},[_c('div',{staticClass:"priceLeft"},[_c('div',{staticClass:"prices"},[_c('span',{staticClass:"mainTitle"},[_vm._v(_vm._s(_vm.classInfo.courseName))]),_c('span',{staticClass:"subTitle"},[_vm._v("服务价格：")]),_c('span',{staticStyle:{"font-size":"28px","color":"#de1306","line-height":"42px","vertical-align":"top"}},[_vm._v(" $"+_vm._s(_vm.classInfo.usdAmount))]),_c('span',{staticStyle:{"font-size":"16px","color":"#e02020","line-height":"42px","vertical-align":"top"}},[_vm._v(" / ¥"+_vm._s(_vm.classInfo.amount))])])]),_c('div',{staticClass:"priceRight"},[_vm._m(5),_c('el-button',{staticClass:"buyNow",on:{"click":_vm.buyCombo}},[_vm._v("立即购买")])],1)])])])])]),_c('leetDialog')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagebanner"},[_c('img',{attrs:{"src":require("@/assets/mock/behaviorGuidance/indexBanner.png"),"alt":""}}),_c('div',{staticClass:"pageTitle"},[_vm._v(" Behavior Questions 1v1模拟面试 "),_c('div',{staticClass:"subtitle"},[_vm._v(" 顶尖FMAG大厂面试官，根据你的个人履历及面试岗位定制Questions "),_c('br'),_vm._v(" 分析面试过程中的Behavior，并针对性的训练及教会你应答技巧 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("四招搞定Behavior Questions 提前准备，轻松应对")]),_c('p',[_vm._v("顶尖FMAG大厂面试官，针对性1v1模拟面试")])]),_c('div',{staticClass:"plans"},[_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/behaviorGuidance/plan1.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("应答具体")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 明确岗位所需的基本素养与资深能力的匹配 "),_c('br'),_vm._v(" 教会你根据特定问题倾向有针对性，具体化应答 ")])])]),_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/behaviorGuidance/plan2.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("能力定量")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 如何用定量化的方式去衡量你的优势及能力 "),_c('br'),_vm._v(" 可以更真实的丰富你的经历 ")])])]),_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/behaviorGuidance/plan3.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("语言简洁")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 面试中精炼的语言，能让面试官更快速的了解你 "),_c('br'),_vm._v(" 没有额外的干扰信息，能让你更突出 ")])])]),_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/behaviorGuidance/plan4.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("充足准备")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 有目的性的准备能更好的让你面对企业面试 "),_c('br'),_vm._v(" 更有针对性的强调具体的素质和技能 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("FMAG级别面试官一对一指导")])]),_c('div',{staticClass:"plans"},[_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/behaviorGuidance/plan5.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("1v1专业指导")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 通用型问题指导回答，简历分析针对性题问题 ")])])]),_c('div',{staticClass:"plan"},[_c('img',{attrs:{"src":require("@/assets/mock/behaviorGuidance/plan6.png"),"alt":""}}),_c('div',{staticClass:"plan-rightText"},[_c('div',{staticClass:"plan-title"},[_vm._v("FMAG面试官")]),_c('div',{staticClass:"plan-description"},[_vm._v(" 有经验的面试官，针对简历提出不同的问题 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section gray"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("服务形式")]),_c('div',{staticClass:"title-description"},[_vm._v(" 资深FMAG面试官1对1服务，单次90分钟 ")]),_c('div',{staticClass:"title-description"},[_vm._v(" Online模式：无地区限制，使用Skype远程音频 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-header"},[_c('h3',[_vm._v("快速报名，获取你的专属定制面试策略")])]),_c('div',{staticClass:"fourSteps"},[_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon1.png"),"alt":""}}),_c('br'),_vm._v(" 免费咨询 "),_c('br'),_vm._v(" 定制专属服务 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon2.png"),"alt":""}}),_c('br'),_vm._v(" 在线支付 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon3.png"),"alt":""}}),_c('br'),_vm._v(" 上传简历 "),_c('br'),_vm._v(" 精准推荐面试官 ")]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("@/assets/mock/mockInterview/arrow.png"),"alt":""}})]),_c('div',{staticClass:"step"},[_c('img',{staticClass:"getInIcon",attrs:{"src":require("@/assets/mock/mockInterview/icon4.png"),"alt":""}}),_c('br'),_vm._v(" 1v1结构化面试 "),_c('br'),_vm._v(" 收获完整面试报告 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricesTips"},[_c('div',{staticClass:"dollar"},[_vm._v("支持美元分期付款")]),_c('div',{staticClass:"huabei"},[_vm._v("支持花呗分期")])])
}]

export { render, staticRenderFns }